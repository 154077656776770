import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { isRouteErrorResponse, useNavigate } from "react-router-dom";

const RouteError = ({ error }) => {
  const navigate = useNavigate();

  return (
    <Grid direction="column" container>
      <Grid item xs={12}>
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Go back
            </Button>
          }
        >
          <AlertTitle>
            {isRouteErrorResponse(error)
              ? error.data.title ?? error.statusText
              : "Something went wrong. "}
          </AlertTitle>
          {isRouteErrorResponse(error)
            ? error.data.text ?? error.data
            : "If the error is persistent, contact support. "}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default RouteError;
