import { Grid, Paper } from "@mui/material";

import { json, redirect } from "react-router-dom";

import { post } from "../apiClient";
import RetargetingPixelForm from "../features/retargeting_pixels/RetargetingPixelForm";
import useTitle from "../hooks/useTitle";

export async function action({ request }) {
  const formData = await request.formData();
  try {
    const pixel = await post(
      "player",
      "/api/v1/retargeting_pixels",
      {
        name: formData.get("name"),
        url: formData.get("url"),
        is_click_pixel: formData.get("is_click_pixel"),
      },
      { signal: request.signal }
    );

    return redirect(`/retargeting_pixels/${pixel.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
}

export default function NewRetargetingPixel() {
  useTitle("New Pixel");
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <RetargetingPixelForm action="/retargeting_pixels/new" />
        </Paper>
      </Grid>
    </Grid>
  );
}
