import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { debounce, pick, toggle } from "radash";

import {
  Form,
  json,
  Link,
  Outlet,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
  useSubmit,
} from "react-router-dom";
import { get } from "../apiClient";
import TableHeadCell from "../components/TableHeadCell";

import { useEffect, useMemo, useRef, useState } from "react";
import useTitle from "../hooks/useTitle";
import formatNumber from "../utils/formatNumber";

const PAGINATION_DEFAULTS = {
  limit: "25",
  offset: "0",
  orderBy: "id",
  orderDirection: "desc",
};

const StyledLink = styled(Link)(
  () => `
  color: #64B5C5;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
);

export const loader = async ({ request }) => {
  const searchParams = Object.fromEntries(new URL(request.url).searchParams);
  const filters = pick(searchParams, ["searchTerm"]);

  try {
    const resp = await get("audience", "/v1/audiences", {
      params: {
        ...filters,
        pagination: {
          ...PAGINATION_DEFAULTS,
          ...pick(searchParams, Object.keys(PAGINATION_DEFAULTS)),
        },
      },
      signal: request.signal,
    });

    return { audiences: resp.result, totalCount: resp.total_count };
  } catch (error) {
    throw json(
      {
        title: "There was an error fetching the audiences from the server",
        text: " Please try again. If the error is persistent, contact support.",
      },
      { status: error.status }
    );
  }
};

const Audiences = () => {
  useTitle("Audiences");
  const formRef = useRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);
  const { audiences, totalCount } = useLoaderData();
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: 300 }, submit),
    [submit]
  );
  const navigation = useNavigation();
  const [selectedRows, setSelectedRows] = useState([]);
  const onSortOrderChange = (_event, sortOrder) => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev),
      ...sortOrder,
    }));
  };

  const isRowSelected = (audienceId) => selectedRows.includes(audienceId);

  useEffect(() => {
    if (navigation.state === "loading") {
      formRef.current.elements.searchTerm.value = new URLSearchParams(
        navigation.location.search
      ).get("searchTerm");
    }
  }, [navigation.location, navigation.state]);

  useEffect(() => {
    setSelectedRows([]);
  }, [audiences]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", mb: 4, justifyContent: "flex-end" }}>
            <ButtonGroup variant="contained">
              <Button onClick={() => navigate("/audiences/new")}>
                New Audience
              </Button>
            </ButtonGroup>
          </Box>
          <>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={8}>
                <Form
                  ref={formRef}
                  method="get"
                  onChange={(e) => {
                    const isFirstSearch =
                      searchParams.get("searchTerm") === null;
                    submitDebounced(e.currentTarget, {
                      replace: !isFirstSearch,
                    });
                  }}
                >
                  <TextField
                    defaultValue={searchParams.get("searchTerm") ?? ""}
                    fullWidth
                    name="searchTerm"
                    label="Search Audiences"
                    type="search"
                  />
                </Form>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}
                >
                  <ButtonGroup variant="contained">
                    <Button
                      onClick={() => {
                        navigate("/audiences/bulk_delete", {
                          state: { audience_ids: selectedRows },
                        });
                      }}
                      disabled={selectedRows.length === 0}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
            </Grid>

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="select-all" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < audiences.length
                        }
                        checked={
                          audiences.length > 0 &&
                          selectedRows.length === audiences.length
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedRows(audiences.map(({ id }) => id));
                            return;
                          }

                          setSelectedRows([]);
                        }}
                        inputProps={{
                          "aria-label": "select all audiences",
                        }}
                      />
                    </TableCell>
                    <TableHeadCell
                      orderBy={searchParams.get("orderBy")}
                      orderDirection={searchParams.get("orderDirection")}
                      name={"name"}
                      title={"Audience Key"}
                      onSortOrderChange={onSortOrderChange}
                    />
                    <TableHeadCell
                      orderBy={searchParams.get("orderBy")}
                      orderDirection={searchParams.get("orderDirection")}
                      name={"human_readable_name"}
                      title={"Audience Name"}
                      onSortOrderChange={onSortOrderChange}
                    />
                    <TableHeadCell
                      orderBy={searchParams.get("orderBy")}
                      orderDirection={searchParams.get("orderDirection")}
                      name={"targeting_count"}
                      title={"Size"}
                      onSortOrderChange={onSortOrderChange}
                    />
                    <TableHeadCell
                      orderBy={searchParams.get("orderBy")}
                      orderDirection={searchParams.get("orderDirection")}
                      name={"segment_count"}
                      title={"Number of Segments"}
                      onSortOrderChange={onSortOrderChange}
                    />
                    <TableCell key="managedplus-audience-category">
                      {"Managed+ Audience Category"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audiences.map((audience) => (
                    <TableRow
                      key={audience.id}
                      role="checkbox"
                      selected={isRowSelected(audience.id)}
                      onClick={(_event) =>
                        setSelectedRows((rows) => toggle(rows, audience.id))
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isRowSelected(audience.id)}
                          inputProps={{
                            "aria-labelledby": `audience-checkbox-${audience.id}`,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <StyledLink to={`${audience.id}`}>
                          {audience.name}
                        </StyledLink>
                      </TableCell>
                      <TableCell>{audience.human_readable_name}</TableCell>
                      <TableCell>
                        {formatNumber(audience.targeting_count)}
                      </TableCell>
                      <TableCell>
                        {formatNumber(audience.segment_count)}
                      </TableCell>
                      <TableCell>
                        {audience.managedplus_category_name
                          ? audience.managedplus_category_name
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCount}
              onPageChange={(_event, page) => {
                setSearchParams((prev) => ({
                  ...Object.fromEntries(prev),
                  offset: Math.max(page * Number(prev.get("limit")), 0),
                }));
              }}
              onRowsPerPageChange={(event) => {
                setSearchParams((prev) => {
                  return {
                    ...Object.fromEntries(prev),
                    limit: event.target.value,
                    offset:
                      Number(event.target.value) *
                      Math.floor(
                        Number(prev.get("offset")) / Number(event.target.value)
                      ),
                  };
                });
              }}
              page={Math.floor(
                Number(searchParams.get("offset")) /
                  Number(searchParams.get("limit"))
              )}
              rowsPerPage={Number(searchParams.get("limit"))}
              rowsPerPageOptions={[25, 50, 100]}
              SelectProps={{
                native: true,
              }}
            />
          </>
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Audiences;
