import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  json,
  redirect,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { get, patch } from "../apiClient";
import RetargetingPixelForm from "../features/retargeting_pixels/RetargetingPixelForm";
import useTitle from "../hooks/useTitle";

const humanize = ({ human_readable_name, name }) => human_readable_name || name;

const CodeBox = styled(Box)`
  font-family: monospace;
  font-size: 14px;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export async function action({ params, request }) {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);

  try {
    const pixel = await patch(
      "player",
      `/api/v1/retargeting_pixels/${params.id}`,
      formEntries
    );
    return redirect(`/retargeting_pixels/${pixel.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422:
        return json(error.details, { status: error.status });
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const deactivatePixel = async (pixelId) => {
  return await patch("player", `/api/v1/retargeting_pixels/${pixelId}/`, {
    active: false,
  });
};

export async function loader({ params, request }) {
  try {
    const pixel = await get(
      "player",
      `/api/v1/retargeting_pixels/${params.id}`,
      {
        signal: request.signal,
      }
    );
    return pixel;
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Retargeting pixel not found",
            text: `The retargeting pixel with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      case 422:
        throw new json(
          {
            title: "Invalid retargeting pixel ID",
            text: `The retargeting pixel ID ${params.id} is not valid.`,
          },
          { status: 422 }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const RetargetingPixel = ({ isEditing = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const pixel = useLoaderData();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useTitle(`RETARGETING PIXEL ${pixel.name}`);
  const pixelTag = pixel.url
    ? pixel.pixel_code
    : `<img src="${pixel.pixel_code}" width="1" height="1" style="display: none;" alt="" />`;

  async function deleteCurrentPixel() {
    try {
      const response = await deactivatePixel(id);
      if (
        response.message === "Retargeting pixel was successfully deactivated."
      ) {
        navigate("/retargeting_pixels/?deleted=true");
      }
    } catch (error) {
      throw error;
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ px: 3, py: 2 }}>
            <>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Typography sx={{ wordBreak: "break-word" }} variant="h5">
                    {humanize(pixel)}
                  </Typography>
                </Box>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(isEditing ? `/retargeting_pixels/${id}` : "edit")
                    }
                  >
                    {isEditing ? "Cancel" : "Edit"}
                  </Button>
                  {!isEditing && (
                    <Button
                      variant="contained"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              </Box>
              <Divider sx={{ my: 2 }} />
              {isEditing ? (
                <RetargetingPixelForm
                  action={`/retargeting_pixels/${id}/edit`}
                  isEditing
                  pixel={pixel}
                />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Pixel Name
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {pixel.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Pixel ID
                    </Typography>
                    {pixel.id + 10000000}
                  </Grid>
                  {pixel.url && (
                    <Grid item xs={12}>
                      <Typography color="text.secondary" variant="subtitle2">
                        Destination URL
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {pixel.url}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography color="text.secondary" variant="subtitle2">
                        Pixel Code
                      </Typography>
                      <IconButton
                        aria-label="copy"
                        onClick={(e) => navigator.clipboard.writeText(pixelTag)}
                      >
                        <Tooltip id="button-report" title="Copy to clipboard">
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    </Stack>
                    <i>
                      Paste the following code snippet on Admin on the Tracking
                      Impression HTML/1x1 Image Tag or embed it in a custom
                      creative as applicable.
                    </i>
                    <br />
                    <br />
                    <CodeBox>{pixelTag}</CodeBox>
                  </Grid>
                </Grid>
              )}
            </>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <DialogContent>
          <DialogContentText style={{ padding: "24px" }}>
            Deleting this pixel marks it as inactive and does not delete any of
            the associated segments. Please remember to remove it from existing
            campaigns.
            <br />
            Are you sure you want to delete this pixel?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteCurrentPixel()} variant="contained">
            Confirm
          </Button>
          <Button onClick={() => setShowDeleteModal(false)} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RetargetingPixel;
