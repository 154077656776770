import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { debounce, pick } from "radash";

import {
  Form,
  json,
  Link,
  Outlet,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
  useSubmit,
} from "react-router-dom";
import { get } from "../apiClient";
import TableHeadCell from "../components/TableHeadCell";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useTitle from "../hooks/useTitle";

const StyledTableCell = styled(TableCell)`
  max-width: 200px; /* Adjust the width as needed */
  overflow: hidden;
  word-wrap: break-word; /* Breaks long words to fit within the cell */
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PAGINATION_DEFAULTS = {
  limit: "25",
  offset: "0",
  orderBy: "id",
  orderDirection: "desc",
};

const PIXEL_DEACTIVATED_PARAM = "deleted";

const StyledLink = styled(Link)(
  () => `
  color: #64B5C5;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
);
export const loader = async ({ request }) => {
  const searchParams = Object.fromEntries(new URL(request.url).searchParams);
  const filters = pick(searchParams, ["searchTerm"]);

  try {
    const resp = await get("player", "/api/v1/retargeting_pixels", {
      params: {
        ...filters,
        pagination: {
          ...PAGINATION_DEFAULTS,
          ...pick(searchParams, Object.keys(PAGINATION_DEFAULTS)),
        },
      },
      signal: request.signal,
    });

    return { pixels: resp.result, totalCount: resp.total_count };
  } catch (error) {
    throw json(
      {
        title:
          "There was an error fetching the retargeting pixels from the server",
        text: " Please try again. If the error is persistent, contact support.",
      },
      { status: error.status }
    );
  }
};

const RetargetingPixels = () => {
  useTitle("Retargeting Pixels");
  const formRef = useRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { pixels, totalCount } = useLoaderData();
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: 300 }, submit),
    [submit]
  );
  const navigation = useNavigation();

  const closeDialog = useCallback(() => {
    setDeleteDialog(false);
    searchParams.delete(PIXEL_DEACTIVATED_PARAM);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (searchParams.get(PIXEL_DEACTIVATED_PARAM) === "true") {
      setDeleteDialog(true);

      const timer = setTimeout(() => {
        closeDialog();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [searchParams, closeDialog]);

  const onSortOrderChange = (_event, sortOrder) => {
    setSearchParams((prev) => ({
      ...Object.fromEntries(prev),
      ...sortOrder,
    }));
  };

  useEffect(() => {
    if (navigation.state === "loading") {
      formRef.current.elements.searchTerm.value = new URLSearchParams(
        navigation.location.search
      ).get("searchTerm");
    }
  }, [navigation.location, navigation.state]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={8}>
                  <Form
                    ref={formRef}
                    method="get"
                    onChange={(e) => {
                      const isFirstSearch =
                        searchParams.get("searchTerm") === null;
                      submitDebounced(e.currentTarget, {
                        replace: !isFirstSearch,
                      });
                    }}
                  >
                    <TextField
                      defaultValue={searchParams.get("searchTerm") ?? ""}
                      fullWidth
                      name="searchTerm"
                      label="Search Pixels"
                      type="search"
                    />
                  </Form>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonGroup variant="contained">
                    <Button onClick={() => navigate("/retargeting_pixels/new")}>
                      New Pixel
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableHeadCell
                        orderBy={searchParams.get("orderBy")}
                        orderDirection={searchParams.get("orderDirection")}
                        name={"name"}
                        title={"Pixel Name"}
                        onSortOrderChange={onSortOrderChange}
                      />
                      <TableHeadCell
                        orderBy={searchParams.get("orderBy")}
                        orderDirection={searchParams.get("orderDirection")}
                        name={"url"}
                        title={"Destination URL"}
                        onSortOrderChange={onSortOrderChange}
                      />
                      <TableHeadCell
                        orderBy={searchParams.get("orderBy")}
                        orderDirection={searchParams.get("orderDirection")}
                        name={"id"}
                        title={"Pixel ID"}
                        onSortOrderChange={onSortOrderChange}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pixels.map((pixel) => (
                      <TableRow key={pixel.id} role="checkbox">
                        <StyledTableCell>
                          <StyledLink to={`${pixel.id}`}>
                            {pixel.name}
                          </StyledLink>
                        </StyledTableCell>
                        {pixel.url ? (
                          <StyledTableCell>
                            <a
                              href={pixel.url}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {pixel.url}{" "}
                            </a>
                          </StyledTableCell>
                        ) : (
                          <TableCell>N/A</TableCell>
                        )}
                        <TableCell>{pixel.id + 10000000}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalCount}
                onPageChange={(_event, page) => {
                  setSearchParams((prev) => ({
                    ...Object.fromEntries(prev),
                    offset: Math.max(page * Number(prev.get("limit")), 0),
                  }));
                }}
                onRowsPerPageChange={(event) => {
                  setSearchParams((prev) => {
                    return {
                      ...Object.fromEntries(prev),
                      limit: event.target.value,
                      offset:
                        Number(event.target.value) *
                        Math.floor(
                          Number(prev.get("offset")) /
                            Number(event.target.value)
                        ),
                    };
                  });
                }}
                page={Math.floor(
                  Number(searchParams.get("offset")) /
                    Number(searchParams.get("limit"))
                )}
                rowsPerPage={Number(searchParams.get("limit"))}
                rowsPerPageOptions={[25, 50, 100]}
                SelectProps={{
                  native: true,
                }}
              />
            </>
            <Outlet />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        style={{ textAlign: "center" }}
        open={deleteDialog}
        onClick={closeDialog}
        onClose={closeDialog}
        maxWidth="sm"
        maxHeight="sm"
        fullWidth
        fullHeight
      >
        <DialogContent>
          <DialogContentText style={{ padding: "44px" }}>
            Pixel marked inactive.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RetargetingPixels;
