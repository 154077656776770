import { Grid, Paper } from "@mui/material";

import { json, redirect, useRouteLoaderData } from "react-router-dom";

import { post } from "../apiClient";
import AdvertiserForm from "../features/advertisers/AdvertiserForm";
import { extractIds } from "../features/helpers";
import useTitle from "../hooks/useTitle";

const action = async ({ request }) => {
  try {
    const formData = await request.formData();
    const advertiserCategoryIds = extractIds(
      formData.get("advertiser_category_ids")
    );

    await post(
      "player",
      "/api/v1/advertisers",
      {
        name: formData.get("name"),
        domain: formData.get("domain"),
        type: formData.get("type"),
        advertiser_category_ids: advertiserCategoryIds,
      },
      { signal: request.signal }
    );

    return redirect("/advertisers");
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const NewAdvertiser = () => {
  useTitle("New Advertiser");
  const advertiserCategories = useRouteLoaderData("advertiserCategories");

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container justifyContent="center">
        <Grid item xl={5} lg={8} md={10} sm={12} xs={12} sx={{ minWidth: 750 }}>
          <AdvertiserForm
            action="/advertisers/new"
            advertiserCategories={advertiserCategories}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export { action };
export default NewAdvertiser;
