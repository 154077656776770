import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Clear } from "@mui/icons-material";

import { construct } from "radash";

import {
  json,
  redirect,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { get, patch } from "../apiClient";
import AudienceForm from "../features/audiences/AudienceForm";
import useTitle from "../hooks/useTitle";
import createFormErrors from "../utils/createFormErrors";
import formatNumber from "../utils/formatNumber";

const humanize = ({ human_readable_name, name }) => human_readable_name || name;
const deleteIcon = (
  <Clear fontSize="small" variant="filled" sx={{ color: "#fff !important" }} />
);
const DEFAULT_CHIP_PROPS = {
  sx: { background: "#64B5C5" },
  deleteIcon: deleteIcon,
  variant: "filled",
  color: "primary",
};
const segmentSize = (segments) => (segments ?? []).length;

export async function action({ params, request }) {
  const formData = await request.formData();
  const formEntries = construct(Object.fromEntries(formData));

  const segmentIds =
    formEntries["segment_ids"]?.length > 0
      ? formEntries["segment_ids"].split(",")
      : [];
  formEntries["segment_ids"] = segmentIds;

  try {
    const audience = await patch(
      "audience",
      `/v1/audiences/${params.id}`,
      formEntries
    );
    return redirect(`/audiences/${audience.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422:
        return json(
          { errors: createFormErrors(error.details.errors) },
          { status: error.status }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

export async function loader({ params, request }) {
  try {
    const audience = await get("audience", `/v1/audiences/${params.id}`, {
      signal: request.signal,
    });
    return json(audience);
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Audience not found",
            text: `The audience with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      case 422:
        throw new json(
          {
            title: "Invalid audience ID",
            text: `The audience ID ${params.id} is not valid.`,
          },
          { status: 422 }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const Audience = ({ isEditing = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const audience = useRouteLoaderData("audience");
  useTitle(`AUDIENCE ${humanize(audience)}`);
  const segmentNames = (audience.segments ?? []).map(humanize);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ px: 3, py: 2 }}>
            <>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Typography sx={{ mr: 1 }} variant="h5">
                    {humanize(audience)}
                  </Typography>
                </Box>
                <ButtonGroup variant="contained">
                  <Button
                    onClick={() =>
                      navigate(isEditing ? `/audiences/${id}` : "edit")
                    }
                  >
                    {isEditing ? "Cancel" : "Edit"}
                  </Button>
                </ButtonGroup>
              </Box>
              <Divider sx={{ my: 2 }} />
              {isEditing ? (
                <AudienceForm
                  action={`/audiences/${id}/edit`}
                  isEditing
                  audience={audience}
                />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Audience Key
                    </Typography>
                    {audience.name}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Audience Name
                    </Typography>
                    {audience.human_readable_name ?? "-"}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Size
                    </Typography>
                    {formatNumber(audience.targeting_count)}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="subtitle2">
                      # Segments
                    </Typography>
                    {formatNumber(segmentSize(audience.segments))}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="text.secondary" variant="subtitle2">
                      Segments
                    </Typography>
                    <Box sx={{ py: 2 }}>
                      <Autocomplete
                        multiple
                        value={segmentNames}
                        options={segmentNames}
                        disableClearable
                        ChipProps={DEFAULT_CHIP_PROPS}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Segments" />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="text.secondary" variant="subtitle2" sx={{ fontWeight: 700 }}>
                      Available on Managed+
                    </Typography>
                    <Box sx={{ py: 1 }}>
                      {audience?.managedplus_audience ? "Yes" : "No"}
                    </Box>
                  </Grid>
                  {audience.managedplus_audience && (
                    <>
                      <Grid item xs={12}>
                        <Typography color="text.secondary" variant="subtitle2" sx={{ fontWeight: 700 }}>
                          Managed+ Audience Category
                        </Typography>
                        <Box sx={{ py: 1 }}>
                          {
                            audience.managedplus_audience
                              .managedplus_category_name
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="text.secondary" variant="subtitle2" sx={{ fontWeight: 700 }}>
                          Managed+ Audience Display Name
                        </Typography>
                        <Box sx={{ py: 1 }}>
                          {audience.managedplus_audience.display_name}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Audience;
