import { Button, Checkbox, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { Form, useActionData } from "react-router-dom";

const RetargetingPixelForm = ({
  action,
  isEditing = false,
  pixel = {
    id: null,
    url: null,
    name: null,
  },
}) => {
  const form = useActionData();
  const formErrors = form?.errors || {};
  const [isClickPixel, setIsClickPixel] = useState(false);

  return (
    <Form
      method="post"
      action={action}
      role="form"
      name="retargeting-pixel-form"
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Pixel Name"
            name="name"
            error={"name" in formErrors}
            helperText={formErrors["name"]}
            defaultValue={pixel.name}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        {!isEditing && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Checkbox
              style={{ width: 20, height: 20 }}
              checked={isClickPixel}
              name="is_click_pixel"
              onClick={(e) => setIsClickPixel(!isClickPixel)}
            />{" "}
            Click Retargeting Pixel
          </Grid>
        )}
        {isClickPixel && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Destination URL"
              name="url"
              error={"url" in formErrors}
              helperText={formErrors["url"]}
              defaultValue={pixel.url}
              variant="standard"
            />
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button type="submit" variant="contained">
            {isEditing
              ? "Save Changes"
              : isClickPixel
              ? "Create Click Retargeting Pixel"
              : "Create Impression Retargeting Pixel"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RetargetingPixelForm;
