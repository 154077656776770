import { useEffect, useState } from "react";

import { get } from "../apiClient";

export const useData = (service, path) => {
  const [data, setData] = useState({ loading: false, data: null, error: null });

  useEffect(() => {
    let ignore = false;
    setData((prev) => ({ ...prev, loading: true }));
    get(service, path)
      .then((response) => {
        if (!ignore) {
          setData((prev) => ({ loading: false, data: response, error: null }));
        }
      })
      .catch((error) => {
        if (!ignore) {
          setData((prev) => ({ data: null, loading: false, error: error }));
        }
      });

    return () => {
      ignore = true;
    };
  }, [service, path]);

  return data;
};
