import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { json, redirect } from "react-router-dom";
import { post } from "../apiClient";

export const CONTENT_RATING = [
  { value: "1", label: "G" },
  { value: "2", label: "PG" },
  { value: "3", label: "PG-13" },
  { value: "4", label: "R" },
  { value: "5", label: "Alcohol" },
  { value: "6", label: "Exclude Auto Placement Targeting" },
];

export const REJECTION_REASONS = [
  {
    value: "Mature topics, language, or imagery",
    label: "Mature topics, language, or imagery",
  },
  { value: "URL is broken", label: "URL is broken" },
];

export const URL_STATUS_PENDING = [
  { value: "live", label: "Live" },
  { value: "rejected", label: "Rejected" },
];

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 150,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export function urlType(value) {
  if (value === "" || "episode_link") return "Episode Link";
  if (value === "cta") return "CTA";
}

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 576,
  height: 261,
  bgcolor: "background.paper",
  boxShadow: 24,
  Radius: 10,
  p: 4,
};

export const TableSelect = ({
  items,
  onChange,
  id,
  startingValue,
  disabled,
  clearField,
  ...props
}) => {
  const [item, setItem] = useState(startingValue || "");
  function agregator(value) {
    setItem(value.target.value);
    onChange(value.target.value, id);
  }
  useEffect(() => {
    if (clearField && disabled) {
      setItem("");
    }
  }, [disabled, clearField]);

  return (
    <Select
      {...props}
      size="small"
      disabled={disabled}
      name={id}
      onChange={(e) => {
        agregator(e);
      }}
      value={item}
    >
      {items.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export function isUrlGoodToSave(url) {
  const retorno =
    url[URL_CONTENT_RATING_ID_KEY] !== undefined &&
    (url[URL_STATUS_KEY] === "live" ||
      (url[URL_STATUS_KEY] === "rejected" &&
        url[REJECTION_REASON_KEY] !== undefined &&
        url[REJECTION_REASON_KEY] !== ""));
  return retorno;
}

export const URL_STATUS_KEY = "url_status_id";
export const URL_CONTENT_RATING_ID_KEY = "url_content_rating_id";
export const REJECTION_REASON_KEY = "rejection_reason";

export const action = async ({
  urls_to_be_updated,
  campaignId,
  campaign_status,
}) => {
  try {
    await post("managed_plus", `/campaigns/${campaignId}/update_urls`, {
      urls_to_be_updated: urls_to_be_updated,
      campaign_status: campaign_status,
    });
    return redirect(`managed-plus/podcast-campaigns/${campaignId}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

export const fireUpdateEmail = async (
  campaignId,
  account_email,
  campaignUid
) => {
  try {
    await post("managed_plus", `/campaigns/${campaignId}/send_email_update`, {
      account_email: account_email,
      uid: campaignUid,
    });
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};
