import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField
} from "@mui/material";

import { Form, useActionData } from "react-router-dom";

import { useState, useEffect } from "react";

import { useData } from "../../hooks/useData";

import Autocomplete from "./components/Autocomplete";

import {
  createAutocompleteOptions,
  getServerOptions,
} from "./utils/autocompleteUtils.js";


const AudienceForm = ({
  action,
  isEditing = false,
  audience = {
    id: null,
    human_readable_name: null,
    name: null,
    segments: [],
  },
}) => {
  const managedplus_audience = audience.managedplus_audience || {};
  const [value, setValue] = useState(createAutocompleteOptions(audience?.segments || []));
  const [errors, setErrors] = useState({});
  const [managedplusAudience, setManagedplusAudience] = useState(!!managedplus_audience?.managedplus_category_id);
  const { loading, data } = useData("audience", "/v1/managedplus_audiences/categories");
  const form = useActionData();
  const categories = data?.result || [];

  useEffect(() => {
    if (form && form.errors) {
      setErrors(form.errors)
    }
  }, [form]);

  const clearError = (key) => setErrors((prevErrors) => ({ ...prevErrors, [key]: null }))

  return (
    <Form method="post" action={action} role="form" name="audience-form">
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Key"
            name="name"
            error={!!errors["/name"]}
            helperText={errors["/name"]}
            onChange={() => clearError("/name")}
            onInvalid={(event) => {
              event.preventDefault();
              const validity = event.target.validity;

              if (validity.valueMissing) {
                setErrors((prevErrors) => ({ ...prevErrors, "/name": "Please provide an Audience Key" }))
              } else if(validity.patternMismatch) {
                setErrors((prevErrors) => ({ ...prevErrors, "/name": "Only letters and underscore are allowed" }))
              }
            }}
            required
            inputProps={{ pattern: "^[a-zA-Z_]+$" }}
            defaultValue={audience.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Name"
            name="human_readable_name"
            defaultValue={audience.human_readable_name}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Autocomplete
            data-testid="my-autocomplete"
            getServerOptions={getServerOptions}
            onValueChange={setValue}
            defaultValue={value}
            renderInput={(params) => (
              <TextField
                onChange={(event) => clearError("/segment_ids")}
                error={!!errors["/segment_ids"]}
                helperText={errors["/segment_ids"]}
                {...params}
                label="Search Segments"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setManagedplusAudience((v) => {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      "/managedplus_audience/display_name": null,
                      "/managedplus_audience/managedplus_category_id": null,
                    }))
                    return !v
                  })
                }}
                checked={managedplusAudience}
              />
            }
            label="Managed+ Audience"
            slotProps={{ typography: { sx: { fontWeight: 600 } }}}
          />
        </Grid>
        {managedplusAudience && (
          <>
            <Grid item xs={12}>
              <InputLabel required variant="standard" sx={{ fontWeight: 500, fontSize: "0.875rem", color: "text.primary", lineHeight: "1.3125em", mb: 0.25, letterSpacing: "0.006875em" }}>
                Managed+ Audience Category
              </InputLabel>
              {loading ? (
                <TextField fullWidth placeholder="Loading..." disabled />
              ) : (
                <TextField
                  name="managedplus_audience.managedplus_category_id"
                  fullWidth
                  variant="outlined"
                  select
                  onInvalid={(event) => {
                    event.preventDefault();
                    const validity = event.target.validity;

                    if (validity.valueMissing) {
                      setErrors((prevErrors) => ({ ...prevErrors, "/managedplus_audience/managedplus_category_id": "Please select a Managed+ Category" }))
                    }
                  }}
                  error={!!errors["/managedplus_audience/managedplus_category_id"]}
                  onChange={() => clearError("/managedplus_audience/managedplus_category_id")}
                  helperText={errors["/managedplus_audience/managedplus_category_id"]}
                  required
                  SelectProps={{
                    sx: {
                      "& .MuiSelect-select .notranslate::after": { content: '"Select a Managed+ Category"' },
                      "& .MuiSelect-select .notranslate": { color: "text.disabled" }
                    },
                    defaultValue: managedplus_audience.managedplus_category_id ?? ""
                  }}
                >
                  {categories.map(({ category_id, category_name }) => (
                    <MenuItem key={category_id} value={category_id}>
                      {category_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel required variant="standard" sx={{ fontWeight: 500, fontSize: "0.875rem", color: "text.primary", lineHeight: "1.3125em", mb: 0.25, letterSpacing: "0.006875em" }}>
                Managed+ Audience Display Name
              </InputLabel>
              <TextField
                name="managedplus_audience.display_name"
                onChange={() => clearError("/managedplus_audience/display_name")}
                onInvalid={(event) => {
                  event.preventDefault();
                  const validity = event.target.validity;

                  if (validity.valueMissing) {
                    setErrors((prevErrors) => ({ ...prevErrors, "/managedplus_audience/display_name": "Please provide a Managed+ Display Name" }))
                  }
                }}
                error={!!errors["/managedplus_audience/display_name"]}
                helperText={errors["/managedplus_audience/display_name"]}
                placeholder="Enter the audience name to be displayed on Managed+"
                fullWidth
                required
                variant="outlined"
                defaultValue={managedplus_audience.display_name}
              />
            </Grid>
            <input
              type="hidden"
              name="managedplus_audience.id"
              value={managedplus_audience.id}
            />
          </>
        )}
        {managedplus_audience?.id && (
          <input
            type="hidden"
            name="managedplus_audience.delete"
            value={!managedplusAudience}
          />
        )}
        <input
          type="hidden"
          name="segment_ids"
          value={value.map(({ id }) => id).join(",")}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button type="submit" variant="contained">
            {isEditing ? "Save Changes" : "Create Audience"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AudienceForm;
